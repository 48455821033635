import React, { useState } from "react";
import { NavLink } from "react-router-dom";


import CouponImage from "../images/coupon-image.jpg"
const CouponCategoriesCard = (props) =>{
   
    const { prop1 } = props;
    const [couponsToShow, setCouponsToShow] = useState(10); // Initial number of coupons to display

    const loadMoreCoupons = () => {
      // Increase the number of coupons to display
      setCouponsToShow(couponsToShow + 10); // You can change 10 to the desired number
    };
  
    if (!prop1 || prop1.length === 0) {
      return (
        <div>
          Loading...
        </div>
      );
    }

    return (
        <>
            <div className='left-coupon-container'>
                <div className="left-coupon">
                    <NavLink  to="https://amzn.to/3SpFCKH">
                        <img className="side-coupon-img" src={CouponImage} alt="Coupon"/>                        
                    </NavLink>                  
                </div>
            </div>
            <div className="coupon-categories-container">
                <div className="card-category-container">
                    {prop1.map((coupon) => (
                    <div className="card" key={coupon.ASIN}>
                        <NavLink to={coupon.DetailPageURL}>
                            <div className="top">
                                <div style={{ margin: '10px' }}>
                                <img className="top-img" src={coupon.Images.Primary.Medium.URL} loading="lazy" alt="Amazon Coupon"/>
                                </div>  
                            </div>
                            <div className="bottom">
                                <div className="link">
                                    <span className="card-text">
                                        {coupon?.Offers?.Listings?.[0]?.Promotions?.[0]?.DisplayAmount || coupon?.Offers?.Listings?.[0]?.Price?.Savings?.DisplayAmount}
                                    </span>
                                    <p className="card-text">{coupon.ItemInfo.Title.DisplayValue}</p>
                                    <p>{}</p>
                                </div>
                            </div>
                        </NavLink>
                    </div>
                    )).slice(0, couponsToShow)}
                </div>
                {couponsToShow < prop1.length && (
                    <button onClick={loadMoreCoupons}>Load More Coupons</button>
                )}
            </div>
        </>
    );
};


export default CouponCategoriesCard;