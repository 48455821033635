import CouponCardV2 from './CouponCardV2'; // Import your CouponCard component
import React, {useState} from "react";
import { NavLink } from "react-router-dom";
import CouponImage from "../images/coupon-image.jpg";
import AmazonImage from "../logos/Amazon.svg";
const CouponList = (props) => {
    const { coupons} = props;
  
    const [couponsToShow, setCouponsToShow] = useState(10);
  
    // Function to load more coupons
    const loadMoreCoupons = () => {
      setCouponsToShow(couponsToShow + 10); // Increase by 10
    };

    function generateRandomKey() {
        return Math.random().toString(36).substr(2, 9); // Generates a random alphanumeric key
      }

    return (
        <>
            <div className='left-coupon-container'>
                <div className="left-coupon">
                    <NavLink  to="https://amzn.to/3SpFCKH">
                        <img className="side-coupon-img" src={CouponImage} alt="Amazon Coupon"/>                        
                    </NavLink>                  
                </div>
            </div>
            <div className="coupon-list-container">
                <h2>Verified Amazon Coupons</h2>
                <img className="store-logo" src={AmazonImage} alt="store-logo" />
                <div className="coupon-list">
                
                {coupons && coupons.length > 0 ? (
                    <>
                    {coupons.slice(0, couponsToShow).map((coupon) => (
                        <CouponCardV2
                        key={coupon.id + generateRandomKey()} // Make sure to set a unique key for each card
                        label={coupon.label}
                        description={coupon.description || coupon.title}
                        category={coupon.standard_categories}
                        link={coupon.url}
                        />
                    ))}
                    
                    </>
                ) : (
                    <p>Loading or no best offers available.</p>
                )}
                </div>
                {couponsToShow < (coupons?.length || 0) && (
                    <button onClick={loadMoreCoupons}>Load More Coupons</button>
                )}
            </div>
            <div className='right-coupon-container'>
                <div className="right-coupon">
                    <NavLink  to="https://amzn.to/3SpFCKH">
                        <img className="side-coupon-img" src={CouponImage} alt="Amazon Coupon"/>                        
                    </NavLink>                  
                </div>
            </div>
        </>
    );
  };
  
  export default CouponList;