import React, {Component} from "react";
import {  Link, Outlet } from "react-router-dom";

import TechImage from "../images/tech-image.jpg";
import PopularImage from "../images/popular-image.jpg";
import BeautyImage from "../images/beauty-image.jpg";

import techData from "../Data/techcoupons.json";
import newData from "../Data/newcoupons.json";
import popularData from "../Data/popularcoupons.json";
import hugeOffersData from "../Data/hugecoupons.json";


import CouponC from "../Components/CouponCardV4";

import Amazon from "../logos/Amazon.svg";
class Home extends Component{
    constructor(props){
        super(props);

        this.state = {
            coupons : [],
            popular : [],
            new : [],
            huge : [],
            bestOffers : [],
            techOffers : [],
            amazon : [],
            loading: true
        }
    }
    componentDidMount() {
        let techCount = 0;
        let amazonCount = 0;
        let popCount = 0;
        let hugeCount = 0;

        let newCount = 0;

        const newCoupons = [];
        const popCoupons = [];
        const hugeCoupons = [];

        const seenStores = new Set(); // To keep track of seen stores
        const uniqueCoupons = [];
        const halfCoupon = [];
        const techCoupons = [];
        const amazonCoupons = [];

        for(const coupon of techData.SearchResult.Items){
            if(techCount < 10){
                techCoupons.push({ id: coupon.ASIN, coupons: coupon });
                techCount++;
            }
        }
        for(const coupon of newData.SearchResult.Items){
            if(newCount < 15){
                newCoupons.push({ id: coupon.ASIN, coupons: coupon });
                newCount++;
            }
        }

        for(const coupon of popularData.SearchResult.Items){
            if(popCount < 10){
                popCoupons.push({ id: coupon.ASIN, coupons: coupon });
                popCount++;
            }
        }
        for(const coupon of hugeOffersData.SearchResult.Items){
            if(hugeCount < 10){
                hugeCoupons.push({ id: coupon.ASIN, coupons: coupon });
                hugeCount++;
            }
        }
          /*
        for (let i = 0; i < 10; i++) {
            newCoupons.push({ id: i, coupons: amazonData[i] });
        }  
*/
        this.setState({new: newCoupons, popular: popCoupons, huge: hugeCoupons, techOffers: techCoupons, amazon: amazonCoupons});         
    }
    
    

    render(){
        
        if(!this.state.coupons || this.state.coupons === undefined){
            return <div>
                Loading...
                </div>;
        }
        else if(!this.state.bestOffers || this.state.bestOffers === undefined){
            return <div>
                Loading...
                </div>;
        }
        const categories = [
            'Tech',
            'Fashion',
            'Home & Garden',
            'Grocery',
            'Books',
            'Health & Beauty',
            'Business',
            'Luxury',
            'Automotive',
            'Shoes',
          ];
        return(
            <div className="home" >
                    
                <div className="Deals">
                    <h2>Featured Deals or Coupons</h2>
                    <div>
                            {this.state.bestOffers ? (                              
                                <CouponC prop1={this.state.new}></CouponC>
                            ) : (
                                <p>Loading or no best offers available.</p>
                            )}
                    </div>
                </div>
                <div className="deals-section">
                    <h2>The Best Deals</h2>
                    <p className="card-text home-text">Our Favorite Amazon Coupons</p>
                    <div className="big-deals">
                        <div className="big-deals-container">
                            <Link className="big-deals-url" to="https://amzn.to/46STm55">
                                <div className="img-container">
                                    <img className="amazon-img" src={Amazon} alt="Amazon Coupon"/>
                                    <span className="text">Tech Coupons</span>
                                </div>                           
                            </Link>
                            <div className="big-deals-imgcontainer">
                                <img className="big-deals-img" src={TechImage} alt="Tech"/>
                            </div>                   
                        </div>
                        <div className="big-deals-container">
                            <Link className="big-deals-url" to="https://amzn.to/3Spq2Pa">
                                <div className="img-container">
                                    <img className="amazon-img" src={Amazon} alt="Amazon Coupon"/>
                                    <span className="text">Popular Coupons</span>
                                </div>                           
                            </Link>
                            <div className="big-deals-imgcontainer">
                                <img className="big-deals-img" src={PopularImage} alt="Popular"/>
                            </div>                   
                        </div>
                        <div className="big-deals-container">
                            <Link className="big-deals-url" to="https://amzn.to/3SpFCKH">
                                <div className="img-container">
                                    <img className="amazon-img" src={Amazon} alt="Amazon Coupon"/>
                                    <span className="text">Top Beauty Coupons</span>
                                </div>                           
                            </Link>
                            <div className="big-deals-imgcontainer">
                                <img className="big-deals-img" src={BeautyImage} alt="Beauty"/>
                            </div>                   
                        </div>
                    </div>
                </div>
                <div className="favorites">
                    <h2>Our Favorites</h2>
                    <p className="card-text home-text">Our Teams Favorite Coupons</p>
                    <div className="favs">
                        <div>
                            <CouponC prop1={this.state.popular}></CouponC>
                            
                        </div>
                    </div>
                </div>
                
                <div className="affiliate-section">
                    <h2>Top Categories</h2>
                    <p className="card-text home-text">Browse Our Stores</p>
                    <div className="aff-stores">
                        <Link to="/categories/Tech">
                            <div className="stores">
                                <h3>Electronics</h3>
                            </div>
                        </Link>
                        <Link to="/categories/Fashion">
                            <div className="stores">
                                <h3>Fashion</h3>
                            </div>
                        </Link>
                        <Link to="/categories/Books">
                            <div className="stores">
                                <h3>Books</h3>
                            </div>
                        </Link>
                        <Link to="/categories/Shoes">
                            <div className="stores">
                                <h3>Shoes</h3>
                            </div>
                        </Link>
                        <Link to="/categories/Business">
                            <div className="stores">
                                <h3>Business</h3>
                            </div>
                        </Link>
                        <Link to="/categories/Luxury">
                            <div className="stores">
                                <h3>Luxury</h3>
                            </div>
                        </Link>
                        <Link to="/categories/Grocery">
                            <div className="stores">
                                <h3>Grocery</h3>
                            </div>
                        </Link>
                        <Link to="/categories/Automotive">
                            <div className="stores">
                                <h3>Automotive</h3>
                            </div>
                        </Link>
                    </div>
                </div>
                <div className="half-off-section">
                    <h2>
                        Biggest Offers
                    </h2>
                    <p className="card-text home-text">Shop Our Amazing Deals</p>
                    <div>
                        <CouponC prop1={this.state.huge}></CouponC>
                            
                    </div>
                </div>
                <div className="tech-deals-section">
                    <h2>
                        Tech Deals
                    </h2>
                    <p className="card-text home-text">Big Tech Deals</p>
                    <div>
                        <CouponC prop1={this.state.techOffers}></CouponC>
                    </div>
                </div>
                <h3>Our Categories</h3>
                <div className="category-section">
                    {categories.map((category, index) => (
                        <Link to={`/categories/${category}`} key={category + index} className="category-item">
                            {category}
                        </Link>
                    ))}
                </div>
                <div className="bottom-text-container">
                    <h3>Countless Coupons, Limitless Savings</h3>
                    <p>
                        Welcome to our one-stop destination for exclusive Amazon coupons and promotions! If you're an avid online 
                        shopper looking to maximize savings on your favorite products, you've come to the right place. Our website 
                        is dedicated to curating a diverse array of Amazon coupons, ensuring that you have access to the latest and 
                        greatest promotions across a wide range of categories.
                    </p>
                    <p>
                        Discover unbeatable deals on electronics, fashion, home essentials, beauty products, and more. We handpick and 
                        regularly update our selection to bring you discounts that cater to various interests and preferences. Whether 
                        you're a tech enthusiast hunting for the latest gadgets or a fashionista on the lookout for trendy apparel, our 
                        curated coupons have you covered.
                    </p>
                    <h3>
                        How the CouponBook Works
                    </h3>
                    <p>
                        Our user-friendly website is designed to cater to everyone, from seasoned bargain hunters to first-time shoppers. 
                        We've made it simple to find, apply, and redeem coupons. You can effortlessly uncover the best deals in just a couple 
                        clicks, ensuring that savings are within reach for all.
                    </p>
                </div>
                <Outlet/>
            </div>
        )
    }
    
}

export default Home;