import React, { useState } from 'react';
import { addDoc, collection} from "@firebase/firestore"
import  {firestore} from "../firebase_setup/firebase"

function ContactForm() {

  const [name,setName] = useState("");
  const [email,setEmail] = useState("");
  const [message,setMessage] = useState("");
  const [formSubmitted, setFormSubmitted] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
        const ref = collection(firestore, "contacts");
        addDoc(ref, {
          name,
          email,
          message,
        });
        setName('');
        setEmail('');
        setMessage('');
        setFormSubmitted(true);
      } catch (err) {
        console.error("An error occurred while sending the message:", err);
      }
  };

  return (
    <div className='contact-form-container'>
        <h2>Contact Us</h2>
        {formSubmitted ? (
        <div>
          <p>Message sent successfully! Thank you for reaching out.</p>
          {/* You can add additional content or redirection here */}
        </div>
      ) : (
        <form className='contact-form' onSubmit={handleSubmit}>
            <div>
                <input
                    type="text"
                    id="name"
                    name="name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    placeholder="Name"
                    required
                />
            </div>
            <div>
                <input
                    type="email"
                    id="email"
                    name="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Email"
                    required
                />
            </div>
                <textarea
                    id="message"
                    name="message"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    placeholder="Message"
                    required
                />
            <button type="submit">Submit</button>
        </form>
      )}
    </div>
  );
}

export default ContactForm;