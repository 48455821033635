import React, { useEffect, useState } from "react";
import { useLocation, NavLink } from 'react-router-dom';

const SearchedPage = (props) => {
  const location = useLocation();
  const { term } = location.state;

  // Use local component state to track myArray
  const [myArray, setMyArray] = useState(location.state.myArray || []);
  
  const [loading, setLoading] = useState(true);

  // Use useEffect to update myArray when it becomes available
  useEffect(() => {
    // Assuming some asynchronous logic to fetch myArray
    const fetchMyArray = async () => {
      // Simulate an asynchronous data fetch
      // Replace the following line with your actual fetch logic
      await new Promise(resolve => setTimeout(resolve, 1000));

      // Now set myArray in the component state
      setMyArray(location.state.myArray || []);
      setLoading(false);
    };

    // Call the asynchronous function
    fetchMyArray();
  }, [location.state.myArray]);

  // Use another useEffect to react to changes in myArray
  useEffect(() => {
    // This effect will run whenever myArray changes
    //console.log("Printing array in SearchedPage");
    //console.log(myArray);
  }, [myArray]);

  return (
    <div className="search-page-container">
      <h2>Search Results for: {term}</h2>
      <div className="container">
      {loading ? (
        <p>Loading...</p>
      ) : (
        myArray.map((coupon) => (
            <div className="card" key={coupon.ASIN}>
                <NavLink to={coupon.DetailPageURL}>
                    <div className="top">
                        <div style={{ margin: '10px' }}>
                            <img className="top-img" src={coupon.Images.Primary.Medium.URL} loading="lazy" alt="Amazon Coupon"/>
                        </div>  
                    </div>
                    <div className="bottom" >
                        <div className="link">
                            <span className="card-text">
                            {coupon?.Offers?.Listings?.[0]?.Promotions?.[0]?.DisplayAmount ||
                            (coupon?.Offers?.Listings?.[0]?.Price?.Savings?.DisplayAmount && coupon?.Offers?.Listings?.[0]?.Price?.Savings?.DisplayAmount + " Off") ||
                            coupon?.Offers?.Listings?.[0]?.Price?.DisplayAmount}
                            </span>
                            <p className="card-text">{coupon.ItemInfo.Title.DisplayValue}</p>
                        </div>
                    </div>
                </NavLink>
            </div>
        ))
      )}
      </div>
      
    </div>
  );
}

export default SearchedPage;