import React, { useState, useEffect } from 'react';
import fashionData from "../Data/fashioncoupons.json";
import techData from "../Data/techcoupons.json";
import gardenData from "../Data/gardencoupons.json";
import groceryData from "../Data/grocerycoupons.json";
import bookData from "../Data/bookscoupons.json";
import healthData from "../Data/healthcoupons.json";
import businessData from "../Data/businesscoupons.json";
import luxuryData from "../Data/luxurycoupons.json";
import automotiveData from "../Data/automotivecoupons.json";
import shoesData from "../Data/shoescoupons.json";
import promoData from "../Data/amazonpromos.json";



import SearchIcon from '@mui/icons-material/Search';
import { useNavigate } from 'react-router-dom';

const SearchBar = () => {
    const [searchTerm, setSearchTerm] = useState('');
    const [myArray, setMyArray] = useState([]);
    const [buttonClick, setButtonClick] = useState(0); // State to track button clicks
    const navigate = useNavigate();
    const fileUrls = [
        fashionData,
        techData,
        gardenData,
        groceryData,
        bookData,
        healthData,
        businessData,
        luxuryData,
        automotiveData,
        shoesData
    ];

  useEffect(() => {
    if (buttonClick > 0) {
        // Perform the search and navigation only after buttonClick has been incremented
        performSearch();
        //setMyArray(prevArray => [...prevArray]);
        
      }
  }, [buttonClick]); // Include buttonClick in the dependency array

  const performSearch = () => {
    setMyArray([]);
    for (const fileUrl of fileUrls) {
      const data = fileUrl.SearchResult.Items;
      if (checkJSONForTerm(data, searchTerm)) {
        // If the search term is found, update myArray without duplicates
        // const uniqueData = data.filter(item => !myArray.find(existingItem => existingItem.ASIN === item.ASIN ));
        // setMyArray(prevArray => [...prevArray, ...uniqueData]);
        //console.log("Printing Data");
        //console.log(data);
      }
    }
    //navigate(`/searched?term=${searchTerm}`, { state: { myArray, term: searchTerm } });
  };

  const search = () => {
    setButtonClick(prevClick => prevClick + 1); // Increment buttonClick to trigger useEffect
    setMyArray(prevArray => [...prevArray]);
    navigate(`/searched?term=${searchTerm}`, { state: { myArray, buttonClick, term: searchTerm } });
  };

  const checkJSONForTerm = (data, term) => {
    var found = false;
    if (term !== "") {
      for (const item of data) {
        const itemDisplayValue = JSON.stringify(item.ItemInfo.Title.DisplayValue).toLowerCase();
        const lowercasedTerm = term.toLowerCase();
        if (itemDisplayValue.includes(lowercasedTerm)) {
          const alreadyExists = myArray.some(existingItem => existingItem.ASIN === item.ASIN);
          setMyArray(myArray => [...myArray, item]);
          found = true;
        }
      }
    }
    return found;
  };

  return (
    <div className="search-bar">
      <input
        type="text"
        value={searchTerm}
        onChange={(e) => {
            const newTerm = e.target.value;
            setSearchTerm(newTerm);
            performSearch();
        }}
        placeholder="Search..."
        className="search-input"
      />
      <button className="search-button" onClick={search}><SearchIcon className='icon'/></button>
      
    </div>
  );
};

export default SearchBar;