import { useEffect } from 'react';
import couponData from "../Data/coupondata.json";

const DataFetching = ({ onDataFetched }) => {
  //const [storeCoupons, setStoreCoupons] = useState({});

  useEffect(() => {
    // Simulate data fetching, replace this with your actual data fetching logic
    const fetchedData = [];
    for (const coupon of couponData.offers) {
      const { store } = coupon;
      fetchedData.push(coupon);
    }

    onDataFetched(fetchedData);
  }, []);


  return null; // Data fetching component doesn't render anything
};

export default DataFetching;