import Nav from "./Components/Nav";
import { HashRouter as Router, Routes, Route} from "react-router-dom";
import React, {useRef, useEffect, useState} from "react";
//import Home from "./Pages/Home";
import Home from "./Components/Home";
import './App.css'


import DataFetching from './Components/DataFetching'; // Import your DataFetching component
import CouponList from "./Components/CouponList";

import CouponCategoriesCard from "./Components/CouponCategories";
import SearchedPage from "./Components/SearchedPage";
import ContactForm from "./Components/ContactMe";
import NotFound from "./Pages/404Page";

import Footer from "./Components/Footer";

import fashionData from "./Data/fashioncoupons.json";
import techData from "./Data/techcoupons.json";
import gardenData from "./Data/gardencoupons.json";
import groceryData from "./Data/grocerycoupons.json";
import bookData from "./Data/bookscoupons.json";
import healthData from "./Data/healthcoupons.json";
import businessData from "./Data/businesscoupons.json";
import luxuryData from "./Data/luxurycoupons.json";
import automotiveData from "./Data/automotivecoupons.json";
import shoesData from "./Data/shoescoupons.json";
import promoData from "./Data/amazonpromos.json";
const App = () =>{
 
  const [storeCoupons, setStoreCoupons] = useState({});
  const [promoCoupons, setPromoCoupons] = useState({});
  const [coupons, setCategoryCoupons] = useState({});

  const handleDataFetched = (fetchedData) => {
    const couponsByStore = {};
    const seenStores = new Set();
    const couponsByCategory = {};
    const seenCategories = new Set();

    /* Below Code is for Amazon coupons */
    for(const coupon of techData.SearchResult.Items){
      if (!couponsByCategory["techOffers"]) {
        couponsByCategory["techOffers"] = [];
      }
      handleAddCategoryCoupon(couponsByCategory, "techOffers", coupon);
    }
    for(const coupon of fashionData.SearchResult.Items){
      if (!couponsByCategory["clothingOffers"]) {
        couponsByCategory["clothingOffers"] = [];
      }
      handleAddCategoryCoupon(couponsByCategory, "clothingOffers", coupon);
    }
    for(const coupon of gardenData.SearchResult.Items){
      if (!couponsByCategory["homeOffers"]) {
        couponsByCategory["homeOffers"] = [];
      }
      handleAddCategoryCoupon(couponsByCategory, "homeOffers", coupon);
    }
    for(const coupon of groceryData.SearchResult.Items){
      if (!couponsByCategory["groceryOffers"]) {
        couponsByCategory["groceryOffers"] = [];
      }
      handleAddCategoryCoupon(couponsByCategory, "groceryOffers", coupon);
    }
    for(const coupon of bookData.SearchResult.Items){
      if (!couponsByCategory["bookOffers"]) {
        couponsByCategory["bookOffers"] = [];
      }
      handleAddCategoryCoupon(couponsByCategory, "bookOffers", coupon);
    }
    for(const coupon of healthData.SearchResult.Items){
      if (!couponsByCategory["personalCareOffers"]) {
        couponsByCategory["personalCareOffers"] = [];
      }
      handleAddCategoryCoupon(couponsByCategory, "personalCareOffers", coupon);
    }
    for(const coupon of businessData.SearchResult.Items){
      if (!couponsByCategory["businessOffers"]) {
        couponsByCategory["businessOffers"] = [];
      }
      handleAddCategoryCoupon(couponsByCategory, "businessOffers", coupon);
    }
    for(const coupon of luxuryData.SearchResult.Items){
      if (!couponsByCategory["luxuryOffers"]) {
        couponsByCategory["luxuryOffers"] = [];
      }
      handleAddCategoryCoupon(couponsByCategory, "luxuryOffers", coupon);
    }
    for(const coupon of automotiveData.SearchResult.Items){
      if (!couponsByCategory["autoOffers"]) {
        couponsByCategory["autoOffers"] = [];
      }
      
      handleAddCategoryCoupon(couponsByCategory, "autoOffers", coupon);
    }
    for(const coupon of shoesData.SearchResult.Items){
      if (!couponsByCategory["shoeOffers"]) {
        couponsByCategory["shoeOffers"] = [];
      }
      
      handleAddCategoryCoupon(couponsByCategory, "shoeOffers", coupon);
    }

    for(const coupon of promoData){
      if (!couponsByCategory["promoOffers"]) {
        couponsByCategory["promoOffers"] = [];
      }
      //console.log(coupon);
      handleAddCategoryCoupon(couponsByCategory, "promoOffers", coupon);
    }
    /*
    for(const coupon of CData){
      const { store, standard_categories, label } = coupon;
      if ((standard_categories === 'Automotive')) {      
        if (!couponsByCategory["automotiveOffers"]) {
          couponsByCategory["automotiveOffers"] = [];
        }
        
        handleAddCategoryCoupon(couponsByCategory, "automotiveOffers", coupon);
      }
      else if ((standard_categories === 'Beauty & Grooming')) {      
        if (!couponsByCategory["beautyOffers"]) {
          couponsByCategory["beautyOffers"] = [];
        }
       
        handleAddCategoryCoupon(couponsByCategory, "beautyOffers", coupon);
      }
      else if ((standard_categories === 'Baby & Nursery')) {      
        if (!couponsByCategory["babyOffers"]) {
          couponsByCategory["babyOffers"] = [];
        }
       
        handleAddCategoryCoupon(couponsByCategory, "babyOffers", coupon);
      }
      else if ((standard_categories === 'Cell Phones & Accessories')) {      
        if (!couponsByCategory["cellOffers"]) {
          couponsByCategory["cellOffers"] = [];
        }
        
        handleAddCategoryCoupon(couponsByCategory, "cellOffers", coupon);
      }
      else if ((standard_categories === 'Computers, Tablets & Components')) {      
        if (!couponsByCategory["ctcOffers"]) {
          couponsByCategory["ctcOffers"] = [];
        }
       
        handleAddCategoryCoupon(couponsByCategory, "ctcOffers", coupon);
      }
      else if ((standard_categories === 'Electronic Components & Home Audio')) {      
        if (!couponsByCategory["echOffers"]) {
          couponsByCategory["echOffers"] = [];
        }
       
        handleAddCategoryCoupon(couponsByCategory, "echOffers", coupon);
      }
    }
    */
    /* Below is for normal coupons before Amazon coupons were added */
    /*
    for (const coupon of fetchedData) {
      const { store, standard_categories, label } = coupon;
      if (!seenStores.has(store)) {
        seenStores.add(store);    
      }
      if (!couponsByStore[store]) {
        couponsByStore[store] = [];
      }

      if (!seenCategories.has(store)) {
        seenCategories.add(store);       
      }
      if (standard_categories.includes(',')) {
        // Split the string into an array using the comma as the delimiter
        const stringArray = standard_categories.split(',');
        if ((label === 'Save Big' || label === "Best Offer" || label === "Hot Offer" || label === "25% off")) {
          
          if (!couponsByCategory["bestOffers"]) {
            couponsByCategory["bestOffers"] = [];
          }
          handleAddCategoryCoupon(couponsByCategory, "bestOffers", coupon);
        }
        if((stringArray[0] === "Smart Devices" || stringArray[0] === "Gaming Consoles" || stringArray[0] === "Computers and Laptops" || stringArray[0] === "Mobiles and Tablets" || stringArray[0] === "Gaming" || stringArray[0] === "Computer Accessories") ){
            if (!couponsByCategory["techOffers"]) {
              couponsByCategory["techOffers"] = [];
            }
            handleAddCategoryCoupon(couponsByCategory, "techOffers", coupon);
        }
        else if(stringArray[0] === "Mens Clothing" || stringArray[0] === "Womens Clothing" || stringArray[0] === "Handbags and Wallets"  || stringArray[0] === "Fashion Assessories" || stringArray[0] === "Kids Fashion" || stringArray[0] === "Jewellery" || stringArray[0] === "Fashion Accessories"){
          if (!couponsByCategory["clothingOffers"]) {
            couponsByCategory["clothingOffers"] = [];
          }
          handleAddCategoryCoupon(couponsByCategory, "clothingOffers", coupon);
        }
        else if(stringArray[0] === "Grocery" || stringArray[0] === "Snacks and Drinks" ){
          if (!couponsByCategory["groceryOffers"]) {
            couponsByCategory["groceryOffers"] = [];
          }
          handleAddCategoryCoupon(couponsByCategory, "groceryOffers", coupon);
        }
        else if(stringArray[0] === "Online Printing" || stringArray[0] === "Office Supplies" || stringArray[0] === "Building Supplies"){
          if (!couponsByCategory["businessOffers"]) {
            couponsByCategory["businessOffers"] = [];
          }
          handleAddCategoryCoupon(couponsByCategory, "businessOffers", coupon);
        }
        else if(stringArray[0] === "Gift Items" || stringArray[0] === "Personalized Gifts" || stringArray[0] === "Gift Items"){
          if (!couponsByCategory["giftOffers"]) {
            couponsByCategory["giftOffers"] = [];
          }
          handleAddCategoryCoupon(couponsByCategory, "giftOffers", coupon);
        }
        else if(stringArray[0] === "Makeup Products" || stringArray[0] === "Personal Care Appliances" || stringArray[0] === "Body Care"){
          if (!couponsByCategory["personalCareOffers"]) {
            couponsByCategory["personalCareOffers"] = [];
          }
          handleAddCategoryCoupon(couponsByCategory, "personalCareOffers", coupon);
        }
        else if(stringArray[0] === "Home Appliances" || stringArray[0] === "Gardening Supplies"){
          if (!couponsByCategory["homeOffers"]) {
            couponsByCategory["homeOffers"] = [];
          }
          handleAddCategoryCoupon(couponsByCategory, "homeOffers", coupon);
        }
        else if(stringArray[0] === "Sports" || stringArray[0] === "Fitness"){
          if (!couponsByCategory["sportsOffers"]) {
            couponsByCategory["sportsOffers"] = [];
          }
          handleAddCategoryCoupon(couponsByCategory, "sportsOffers", coupon);
        }
        else if(stringArray[0] === "Medical Supplies"){
          if (!couponsByCategory["medicalOffers"]) {
            couponsByCategory["medicalOffers"] = [];
          }
          handleAddCategoryCoupon(couponsByCategory, "medicalOffers", coupon);
        }
        else if(stringArray[0] === "Automobile Parts"){
          if (!couponsByCategory["autoOffers"]) {
            couponsByCategory["autoOffers"] = [];
          }
          handleAddCategoryCoupon(couponsByCategory, "autoOffers", coupon);
        }
        else if(stringArray[0] === "Graphics"){
          if (!couponsByCategory["graphicOffers"]) {
            couponsByCategory["graphicOffers"] = [];
          }
          handleAddCategoryCoupon(couponsByCategory, "graphicOffers", coupon);
        }
        else{
          if (!couponsByCategory["miscOffers"]) {
            couponsByCategory["miscOffers"] = [];
          }
          handleAddCategoryCoupon(couponsByCategory, "miscOffers", coupon);
        }
      }
      else if(standard_categories !== ""){
          //handleAddCategoryCoupon();
          
      }
      handleAddCoupon(couponsByStore, store, coupon);
    }
    */
    setCategoryCoupons(couponsByCategory);
    setStoreCoupons(couponsByStore);
  };
  
  
  const handleAddCoupon = (couponsByStore, storeName, newCoupon) => {
    couponsByStore[storeName].push(newCoupon);
  };
  
  const handleAddCategoryCoupon = (couponsByCategory, categoryName, newCoupon) => {
    //couponsByStore[storeName].push(newCoupon);
    couponsByCategory[categoryName].push(newCoupon);
  };

  return(
    <Router>
      <div id="nav-bar-container">      
        
        <DataFetching onDataFetched={handleDataFetched} />
        <Nav></Nav>
      </div>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/categories/Tech" element={<CouponCategoriesCard prop1={coupons["techOffers"]} prop2="Tech"/>} />
        <Route path="/categories/Fashion" element={<CouponCategoriesCard prop1={coupons["clothingOffers"]} prop2="Fashion"/>} />
        <Route path="/categories/Grocery" element={<CouponCategoriesCard prop1={coupons["groceryOffers"]} prop2="Grocery"/>} />
        <Route path="/categories/Home & Garden" element={<CouponCategoriesCard prop1={coupons["homeOffers"]} prop2="Home & Garden"/>} />
        <Route path="/categories/Sports & Outdoors" element={<CouponCategoriesCard prop1={coupons["sportsOffers"]} prop2="Sports & Outdoors"/>} />
        <Route path="/categories/Books" element={<CouponCategoriesCard prop1={coupons["bookOffers"]} prop2="Books"/>} />
        <Route path="/categories/Health & Beauty" element={<CouponCategoriesCard prop1={coupons["personalCareOffers"]} prop2="Health & Beauty"/>} />
        <Route path="/categories/Luxury" element={<CouponCategoriesCard prop1={coupons["luxuryOffers"]} prop2="Luxury"/>} />
        <Route path="/categories/Business" element={<CouponCategoriesCard prop1={coupons["businessOffers"]} prop2="Business"/>} />
        <Route path="/categories/Automotive" element={<CouponCategoriesCard prop1={coupons["autoOffers"]} prop2="Automotive"/>} />
        <Route path="/categories/Shoes" element={<CouponCategoriesCard prop1={coupons["shoeOffers"]} prop2="Shoes"/>} />
        <Route path="/categories/Miscellaneous" element={<CouponCategoriesCard prop1={coupons["miscOffers"]} prop2="Categories"/>} />
        <Route path="/bestoffers" element={<CouponList storeName="" coupons={coupons["promoOffers"]} />} />

        <Route path="/searched" element={<SearchedPage/>} />
        <Route path="/contact" element={<ContactForm/>} />
        <Route path="*" element={<NotFound/>} />
      </Routes>
      <Footer></Footer>
    </Router>
  )
}

export default App;
