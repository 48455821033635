import React from "react";
import { NavLink } from "react-router-dom";

const CouponC = (props) =>{
    
    if(!props.prop1 || props.prop1.length === 0){
        return (<div>
            Loading...
            </div>
        )
    }
    if (!props.prop1) {
        // Handle the case where prop1 is undefined or falsy (e.g., empty array)
        return <p>No data available.</p>;
    }

    return(
        <>
        
            <div className="card-container">
                {/* Check if props.prop1[3] exists before accessing its properties */}
                {props.prop1[0] && (
                    <div className="container">
                        {props.prop1.map((coupon) => (
                        <div className="card" key={coupon.id}>
                            <NavLink to={coupon.coupons.DetailPageURL}>
                                <div className="top">
                                    <div style={{ margin: '10px' }}>
                                        <img className="top-img" src={coupon.coupons.Images.Primary.Medium.URL} loading="lazy" alt="Amazon Coupon"/>
                                    </div>  
                                </div>
                                <div className="bottom" >
                                    <div className="link">
                                        <span className="card-text">
                                        {coupon.coupons?.Offers?.Listings?.[0]?.Promotions?.[0]?.DisplayAmount ||
                                        (coupon.coupons?.Offers?.Listings?.[0]?.Price?.Savings?.DisplayAmount && coupon.coupons?.Offers?.Listings?.[0]?.Price?.Savings?.DisplayAmount + " Off") ||
                                        coupon.coupons?.Offers?.Listings?.[0]?.Price?.DisplayAmount}
                                        </span>
                                        <p className="card-text">{coupon.coupons.ItemInfo.Title.DisplayValue}</p>
                                    </div>
                                </div>
                            </NavLink>
                        </div>
                    ))}
                    </div>
                )}
            </div>
        </>
    )
}

export default CouponC;