import React, { useState } from 'react';
import { Link, Outlet } from 'react-router-dom';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import MenuIcon from '@mui/icons-material/Menu';
import SearchBar from './Search';
const Nav = () => {
  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [isMobileMenuVisible, setMobileMenuVisible] = useState(false);

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!isMobileMenuOpen);
    if(isMobileMenuVisible === false){
        setMobileMenuVisible(true);
    }
    else{
        setMobileMenuVisible(false);
    }
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
    if(isDropdownVisible === false){
        setDropdownVisible(true);
    }
    else{
        setDropdownVisible(false);
    }
    
  };

  const closeDropdown = () => {
    setIsDropdownOpen(false);
    setDropdownVisible(false);
  };

  // Add a click event listener to the link that triggers the dropdown if it exists
  const links = document.querySelectorAll('.link-in-dropdown');

  for (let i = 0; i < links.length; i++) {
    links[i].addEventListener('click', closeDropdown);
  }


  return (
    <>
      <div id="Nav">
        <div className="top-header">
          
          <Link className="nav-page-name-container" to="/">
            <h2 className="nav-page-name">The CouponBook</h2>
          </Link>
          <div className="menu-icon">
            <div onClick={toggleMobileMenu}>
              <MenuIcon />
            </div>
          </div>
          <div className={`menu-items ${isMobileMenuVisible ? 'active' : ''}`} style={{ margin: '10px' }}>
            <div id="store-list" className={`menu-item ${isMobileMenuVisible ? 'active' : ''}`} style={{ margin: '10px' }}>
              <div className="link" onClick={toggleDropdown}>
                  <div className="stores-dropdown">
                      <span>Categories</span>
                      {isDropdownOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                  </div>
              </div>
              <div className={`dropdown ${isDropdownVisible ? 'active' : ''}`}>
                  <Link className="link link-in-dropdown" to="/categories/Tech">
                    <span>Tech</span>
                  </Link>
                  <Link className="link link-in-dropdown" to="/categories/Fashion">
                    <span>Fashion</span>
                  </Link>
                  <Link className="link link-in-dropdown" to="/categories/Grocery">
                    <span>Grocery</span>
                  </Link>
                  <Link className="link link-in-dropdown" to="/categories/Books">
                  <span>Books</span>
                  </Link>
                  <Link className="link link-in-dropdown" to="/categories/Health & Beauty">
                  <span>Health & Beauty</span>
                  </Link>
                  <Link className="link link-in-dropdown" to="/categories/Business">
                  <span>Business</span>
                  </Link>
                  <Link className="link link-in-dropdown" to="/categories/Home & Garden">
                  <span>Home & Garden</span>
                  </Link>
                  <Link className="link link-in-dropdown" to="/categories/Luxury">
                  <span>Luxury</span>
                  </Link>
                  <Link className="link link-in-dropdown" to="/categories/Automotive">
                  <span>Automotive</span>
                  </Link>
                  <Link className="link link-in-dropdown" to="/categories/Shoes">
                  <span>Shoes</span>
                  </Link>
              </div>
              </div>
              <div className={`menu-item ${isMobileMenuVisible ? 'active' : ''}`} style={{ margin: '10px' }}>
              <Link className="link link-in-dropdown" to="/bestoffers">
                  <span>Top Promotions</span>
              </Link>
              </div>
          </div>
        </div>
        <div className="bottom-header">
          <div id="home" className="menu-item"style={{ margin: '10px' }}>
            <h2>Search Our Big Deals</h2>
            <SearchBar />
          </div>
        </div>
        
        
        
        {/* Display the MenuIcon when the screen is smaller */}
      </div>
      <Outlet />
    </>
  );
  
};

export default Nav;
