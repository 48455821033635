import { NavLink } from "react-router-dom";
import React from 'react';

const CouponPage = (props) =>{
    return(
        <div className="coupon-card">
            <div>
                <NavLink to={props.link}>
                    <div className="coupon-header">
                        <h3>{props.category}</h3>               
                    </div>
                    <div className="divider"></div>
                    <div className="coupon-description">
                        <p>{props.label}</p>
                    </div>
                </NavLink>
            </div>
        </div>
    )
}

export default CouponPage;