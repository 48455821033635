import React from "react";
import { NavLink } from "react-router-dom";
const Footer = () => {
  return (
    <div className="footer">
        <p>TheCouponBook.net</p>
        <NavLink  to="/Contact">
            <p>Contact us</p>                       
        </NavLink> 
        
    </div>
  );
};

export default Footer;

